import React, { Fragment, useCallback, useRef } from 'react';
import { Form, Button, Alert, Spinner, Card } from 'react-bootstrap';
import Methods from '../assets/methods.png';
import SSL from '../assets/ssl.png';

import { encodeObject, getRosswarepayEndpointUrl } from '../helpers/data';
import { validateNonFullsteamField } from '../helpers/validation';
import { useState } from 'react';
import { useEffect } from 'react';
import { hostedPaymentsPerformBinCheck } from '../helpers/cardBin';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

function waitForElement(id, remainingAttempts = 50) {
  if (remainingAttempts === 0) {
    return Promise.reject(new Error('element not found'));
  }

  return new Promise((resolve, reject) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener('load', () => {
        resolve(element);
      });
    } else {
      setTimeout(() => {
        waitForElement(id, remainingAttempts - 1).then(resolve);
      }, 100);
    }
  });
}

function ValidCheck(props) {
  if (props.valid === true) return <span style={{ color: '#aaffaa' }}>✓</span>
  if (props.valid === false) return <span style={{ color: '#ffaaaa' }}>✕</span>
  return null;
}

function ValidityLabel(props) {
  const {children, valid} = props;

  return <label style={{ color: valid === false ? 'red' : undefined }}>{children} <ValidCheck valid={valid} /></label>
}

function waitForElements(ids) {
  return Promise.all(ids.map(id => waitForElement(id)));
}

function waitForCardElements() {
  return waitForElements([
    'fullsteam-hosted-card-number-frame',
    'fullsteam-hosted-expiration-month-frame',
    'fullsteam-hosted-expiration-year-frame',
    'fullsteam-hosted-cvv-frame',
  ]);
}

export default function SaveToken() {
  const [initialSearchParams] = useSearchParams();
  const [searchParams, setSearchParams] = useState(null);

  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingError, setProcessingError] = useState(null);

  const [payerNameValid, setPayerNameValid] = useState(null);

  const [payerCardNumberValid, setPayerCardNumberValid] = useState(null);
  const [payerExpirationMonthValid, setPayerExpirationMonthValid] = useState(null);
  const [payerExpirationYearValid, setPayerExpirationYearValid] = useState(null);
  const [payerSecurityCodeValid, setPayerSecurityCodeValid] = useState(null);

  const [payerZipValid, setPayerZipValid] = useState(null);
  const [payerAddressValid, setPayerAddressValid] = useState(null);

  const [termsAgreed, setTermsAgreed] = useState(false);

  const rosswarePayRequiredValids = [payerNameValid, payerCardNumberValid, payerExpirationMonthValid, payerExpirationYearValid, payerSecurityCodeValid, payerZipValid, termsAgreed];

  const [business, setBusiness] = useState(null);

  const [fullsteamPayScriptLoaded, setFullsteamPayScriptLoaded] = useState(false);

  const responseTextAreaRef = useRef(null);

  const [globalLoading, setGlobalLoading] = useState(true);
  const [globalMessage, setGlobalMessage] = useState(false);
  function throwMessage(message) {
    setGlobalMessage(message);
    setGlobalLoading(false);
  }

  // this runs once on page load
  useEffect(() => {
    document.title = 'Save your payment method'
    const searchParamsResult = Object.fromEntries(['token', 'customer_name', 'customer_address', 'origin_invoice_number', 'origin_app', 'nickname', 'description'].map(key => {
      const value = initialSearchParams.get(key);
      if (value) {
        return [key, value];
      } else {
        return undefined;
      }
    }).filter(val => val !== undefined));

    if (!searchParamsResult?.token) {
      throwMessage('Token is required');
      return;
    }

    setSearchParams(searchParamsResult);

    var script = document.createElement('script');
    script.onload = function () {
      setFullsteamPayScriptLoaded(true);
    };
    script.src = process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT === 'test' ? 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js' : 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js';

    document.head.appendChild(script);

    return () => {
      script.parentElement?.removeChild?.(script);
      script = null;
    }
  }, [initialSearchParams])

  const doFullSteamSetup = useCallback(() => {
    function saveTokenOnline(params) {
      return new Promise((resolve, reject) => {
        fetch(`${getRosswarepayEndpointUrl('saveToken')}${ encodeObject(params) }`, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${searchParams.token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            resolve(data);
          }).catch(err => {
            console.log(err);
            throwMessage(err.message);
          }
        );
      })
    }

    // FIRST GRAB THE AUTH KEY
    fetch(getRosswarepayEndpointUrl('tokenAuth'), {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${searchParams.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      // THEN SETUP THE FIELDS FOR THE FULLSTEAM PAYMENT
      .then(data => {
        if (!data?.isSuccessful) {
          const errorMessage = data?.responseDetails.map(detail => detail?.message).join(' | ');
          throw new Error(errorMessage);
        }
        setBusiness(data?.business);
        const setupFullsteamHostedPaymentsParameters = {
          disableDuplicateDetection: false,

          authenticationKey: data?.authenticationKey,
          operationType: 'Token',
          cardEntryContext: 'WebConsumerInitiated',
          formId: 'fullsteam-hosted-form',

          nameOnAccountField: 'fullsteam-hosted-name-on-card-input',
          zipField: 'fullsteam-hosted-zip-input',
          address1Field: 'fullsteam-hosted-address-input',

          nameOnAccount: searchParams?.customer_name || '',

          // rw client id
          customerId: data?.business?.business_id || '',
          invoiceNumber: searchParams?.origin_invoice_number || '',

          ignoreFormSubmit: false,

          completionCallback: () => {
            const responseJson = JSON.parse(responseTextAreaRef.current.value);
            if (responseJson?.gatewayResponse?.accountDetails) {
              responseJson.gatewayResponse.accountDetails.cardBrand = hostedPaymentsPerformBinCheck(responseJson?.gatewayResponse?.accountDetails?.cardBIN);
            }

            saveTokenOnline({
              fullsteam_response: JSON.stringify(responseJson),
              origin_invoice_number: searchParams?.origin_invoice_number,
              origin_app: process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT === 'production' ? 'portal.rosswarepay.com' : 'test-portal.rosswarepay.com',
              nickname: searchParams?.nickname,
              description: searchParams?.description,
            }).then(data => {
              throwMessage('Your payment method was stored successfully! Thank you for using our service.');
            }).catch(err => {
              console.log(err);
            })

          },

          showPleaseWait: () => {
            setProcessingPayment(true);
          },
          hidePleaseWait: () => {
            setProcessingPayment(false);
          },

          validationCallback: () => {
            // maybe show validation error here
            return true;
          },

          hostedPaymentsSimpleError: (err) => {
            setProcessingError(err)
          },

          // validations

          cardNumberValid: () => {
            setPayerCardNumberValid(true);
          },
          cardNumberInvalid: () => {
            setPayerCardNumberValid(false);
          },

          cvvValid: () => {
            setPayerSecurityCodeValid(true);
          },
          cvvInvalid: () => {
            setPayerSecurityCodeValid(false);
          },

          expirationMonthValid: () => {
            setPayerExpirationMonthValid(true);
          },
          expirationMonthInvalid: () => {
            setPayerExpirationMonthValid(false);
          },

          expirationYearValid: () => {
            setPayerExpirationYearValid(true);
          },
          expirationYearInvalid: () => {
            setPayerExpirationYearValid(false``);
          },
        }
        window.setupFullsteamHostedPayments(setupFullsteamHostedPaymentsParameters);
        waitForCardElements().then(() => {
          console.log('card elements loaded');
          throwMessage(null);
        })
      }).catch(err => throwMessage(err.message))

  }, [searchParams]);

  useEffect(() => {
    if (fullsteamPayScriptLoaded && searchParams) {
      doFullSteamSetup();
    }
  }, [fullsteamPayScriptLoaded, doFullSteamSetup, searchParams])

  function onRosswarePayInputChange (name, value) {
    switch (name) {
      case 'name_on_card':
        setPayerNameValid(validateNonFullsteamField('fullname', value));
        break;
      case 'zip_code':
        setPayerZipValid(validateNonFullsteamField('zip', value));
        break;
      case 'address':
        setPayerAddressValid(validateNonFullsteamField('address', value));
        break;
      default:
        break;
    }
  }

  const showPaymentForms = () => {
    return _.every([processingPayment], (val) => {return (val === false || val === null)});
  }

  // renderers
  function renderRosswarePayForm() {
    return (
      <Fragment>
      <form id="fullsteam-hosted-form" className="fullSteampayForm" onSubmit={(event) => {
        event.preventDefault();
      }}>

        <div className="px-4 py-3">
          <div>
            <ValidityLabel valid={payerNameValid}>Name on card</ValidityLabel>
            <input name="name_on_card" className='styled-input' id="fullsteam-hosted-name-on-card-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="John N Doe"/>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
              <div>
                <ValidityLabel valid={payerAddressValid}>Street #</ValidityLabel>
                <input name="address" className='styled-input' id="fullsteam-hosted-address-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="123"/>
              </div>
              <div>
                <ValidityLabel valid={payerZipValid}>Zip/postal code</ValidityLabel>
                <input name="zip_code" className='styled-input' id="fullsteam-hosted-zip-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="98584"/>
              </div>
            </div>

            <ValidityLabel valid={payerCardNumberValid}>Card number</ValidityLabel>
            <div className='styled-input' id="fullsteam-hosted-card-number-div"></div>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
              <div>
                <ValidityLabel valid={payerExpirationMonthValid}>Expiration month</ValidityLabel>
                <div className='styled-input' id="fullsteam-hosted-expiration-month-div"></div>
              </div>
              <div>
                <ValidityLabel valid={payerExpirationYearValid}>Expiration year</ValidityLabel>
                <div className='styled-input' id="fullsteam-hosted-expiration-year-div"></div>
              </div>
            </div>

            <ValidityLabel valid={payerSecurityCodeValid}>Security code</ValidityLabel>
            <div className='styled-input' id="fullsteam-hosted-cvv-div"></div>

            <input type="hidden" id="Amount" name="Amount"></input>
            <input type="hidden" id="InvoiceId" name="InvoiceId"></input>
            <input type="hidden" id="TransactionId" name="TransactionId"></input>
            <textarea readOnly hidden ref={responseTextAreaRef} id="HostedPaymentsResponse" name="HostedPaymentsResponse" style={{ fontSize: '8pt', height: '300px', width: '100%' }}></textarea>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <Form.Check checked={termsAgreed} type="checkbox" onChange={() => setTermsAgreed(!termsAgreed)} />
              <span>I agree to <a href="https://rossware.com/terms" target="_blank" rel="noreferrer">terms of use.</a></span>
            </div>
            <Button type="submit" className="btn-primary px-5 py-2" disabled={!_.every(rosswarePayRequiredValids, (val) => val === true)}>Save</Button>
          </div>
        </div>
        </form>
      </Fragment>
    )
  }

  const renderProcessingError = useCallback(() => {
    if (!processingError || processingPayment) {
      return undefined;
    }
    return (
      <Alert variant='danger' className='m-2'>{processingError}</Alert>
    )
  }, [processingPayment, processingError])

  return (
    <Fragment>
      { globalMessage &&
        <div className="global-message-div">
          <div className='global-message'>
            { globalMessage }
          </div>
        </div>
      }
      { globalLoading &&
        <div className="global-message-div">
          <div className='global-message'>
            Loading, please wait...
          </div>
        </div>
      }


      <div className="vh-100 d-flex justify-content-center align-items-center overflowXNone">
        <Card className="wrapper">
          <div className="border-bottom px-4 py-3 text-center">
            <p className='mb-0'>Save your payment information with</p>
            <h5 className="m-0 text-center">{business?.name}</h5>
          </div>

          <div id="payment-form" className={ !showPaymentForms() ? 'd-none' : undefined }>
            {renderRosswarePayForm()}
          </div>

          {processingPayment &&
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner className='m-4' animation="border" role="status" variant="primary">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          }

          {renderProcessingError()}

          <div className="legal-links">
            <a href="https://rossware.com/contact">Contact us</a>
            <a href="https://rossware.com/privacy-policy">Privacy policy</a>
          </div>

          <div className="px-4 py-2 rounded-bottom d-flex justify-content-between align-items-center bg-light">
            <img src={SSL} width="171" alt="SSL Secured" className="d-none d-md-inline" />
            <img src={Methods} width="200px" alt="Payment methods" />
          </div>
        </Card>
      </div>
    </Fragment>
  )

}
