import React, { Fragment } from 'react';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter, RouterProvider,
} from "react-router-dom";
import ParamsToProps from './components/ParamsToProps';
import Payment from './pages/Payment';
import SaveToken from './pages/SaveToken';
import ReactDOM from 'react-dom/client';
import './bootstrap.css';
import './index.css';
import './grid.css';

const router = createBrowserRouter([

  {
    path: "/pay/:payment_id",
    element: <ParamsToProps component={ Payment }/>,
  },
  {
    path: "/pay/*",
    element: <ParamsToProps component={ Payment }/>,
  },
  {
    path: "/save-payment",
    element: <ParamsToProps component={ SaveToken }/>
  },
  {
    path: "*",
    element: <div>Not Found</div>,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Fragment>
    <RouterProvider router={router} />
  </Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
