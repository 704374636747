export function encodeObject(object) {
	if (!object) return '';
	const keys = Object.keys(object);
	return '?' + keys.map((key) => {
		return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
	}).join('&');
}

export default async function apiRequest({ endpoint, parameters, body, method }) {
	let response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}${encodeObject(parameters)}`, { method, body: body ? JSON.stringify(body) : undefined });
	let data = await response.json();
	return { data, status: response.status};
}

export function getRosswarepayEndpointUrl(endpoint) {
	return `${process.env.REACT_APP_API_BASE_URL}/v3/rosswarepay/${process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT || 'test'}/${endpoint}`;
}

