import React, { Fragment, useCallback, useRef } from 'react';
import { Form, FormControl, InputGroup, Button, Alert, Spinner, Card } from 'react-bootstrap';
import Methods from '../assets/methods.png';
import SSL from '../assets/ssl.png';
import apiRequest, { encodeObject, getRosswarepayEndpointUrl } from '../helpers/data';
import { dateToISO } from '../helpers/dates';
import { validateNonFullsteamField, validateTsysPaymentForm } from '../helpers/validation';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { useEffect } from 'react';
import { hostedPaymentsPerformBinCheck } from '../helpers/cardBin';
import _ from 'lodash';
import currency from 'currency.js';
import { DateTime, Interval } from 'luxon';

function waitForElement(id, remainingAttempts = 50) {
  if (remainingAttempts === 0) {
    return Promise.reject(new Error('element not found'));
  }

  return new Promise((resolve, reject) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener('load', () => {
        resolve(element);
      });
    } else {
      setTimeout(() => {
        waitForElement(id, remainingAttempts - 1).then(resolve);
      }, 100);
    }
  });
}

function ValidCheck(props) {
  if (props.valid === true) return <span style={{ color: '#aaffaa' }}>✓</span>
  if (props.valid === false) return <span style={{ color: '#ffaaaa' }}>✕</span>
  return null;
}

function ValidityLabel(props) {
  const {children, valid} = props;

  return <label style={{ color: valid === false ? 'red' : undefined }}>{children} <ValidCheck valid={valid} /></label>
}

function waitForElements(ids) {
  return Promise.all(ids.map(id => waitForElement(id)));
}

function waitForCardElements() {
  return waitForElements([
    'fullsteam-hosted-card-number-frame',
    'fullsteam-hosted-expiration-month-frame',
    'fullsteam-hosted-expiration-year-frame',
    'fullsteam-hosted-cvv-frame',
  ]);
}

function convertStoredDateToUsableIso(storedDate) {
  return storedDate.replace(' ', 'T');
}

export default function Payment(params) {
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingError, setProcessingError] = useState(null);

  const [payerNameValid, setPayerNameValid] = useState(null);

  const [payerCardNumberValid, setPayerCardNumberValid] = useState(null);
  const [payerExpirationMonthValid, setPayerExpirationMonthValid] = useState(null);
  const [payerExpirationYearValid, setPayerExpirationYearValid] = useState(null);
  const [payerSecurityCodeValid, setPayerSecurityCodeValid] = useState(null);
  const [payerZipValid, setPayerZipValid] = useState(null);
  const [payerAddressValid, setPayerAddressValid] = useState(null);

  const [termsAgreed, setTermsAgreed] = useState(false);

  const rosswarePayRequiredValids = [payerNameValid, payerCardNumberValid, payerExpirationMonthValid, payerExpirationYearValid, payerSecurityCodeValid, payerZipValid, termsAgreed];

  const [paymentRequestRecord, setPaymentRequestRecord] = useState(null);
  const [paymentAuth, setPaymentAuth] = useState({});
  const [business, setBusiness] = useState(null);

  const [tsysValid, setTsysValid] = useState(false);

  const [tsysInputs, setTsysInputs] = useState({
    customer_payer_name: null,
    customer_zip_code: null,
    credit_card_number: null,
    credit_card_security_code: null,
    credit_card_expiration: null,
  })

  const [processor, setProcessor] = useState(false);

  const [fullsteamPayScriptLoaded, setFullsteamPayScriptLoaded] = useState(false);

  const responseTextAreaRef = useRef(null);

  const [globalLoading, setGlobalLoading] = useState(true);
  const [globalMessage, setGlobalMessage] = useState(false);

  function throwMessage(message) {
    console.log('throwing error: ', message);
    setGlobalMessage(message);
    setGlobalLoading(false);
  }

  useEffect(() => {
    document.title = 'Submit a payment';
    getPaymentRequestRecord();
    var script = document.createElement('script');
    script.onload = function () {
      setFullsteamPayScriptLoaded(true);
    };
    script.src = process.env.REACT_APP_ROSSWARE_PAY_ENVIRONMENT === 'test' ? 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js' : 'https://hostedpayments.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js';

    document.head.appendChild(script);

    return () => {
      script.parentElement?.removeChild?.(script);
      script = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getPaymentRequestRecord() {
    const { payment_id: paymentIdFromPath } = params;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [paymentIdFromQueryString, uuid] = [urlParams.get('payment_id'), urlParams.get('unique_key')];

    const paymentId = paymentIdFromQueryString || paymentIdFromPath;

    const paymentRequestRecordResponse = await apiRequest({ endpoint: 'v3/getPaymentRequest', parameters: { payment_id: paymentId, unique_key: uuid } });
    const paymentRequestRecord = paymentRequestRecordResponse?.data;

    if (paymentRequestRecordResponse.status !== 200) {
      throwMessage(paymentRequestRecordResponse?.error || 'Something went wrong fetching the payment request.');
      return false;
    }

    const luxonNow = DateTime.now();

    const luxonRequested = DateTime.fromISO(convertStoredDateToUsableIso(paymentRequestRecord.when_requested));

    const hoursSinceInitiated = Interval.fromDateTimes(luxonRequested, luxonNow).length('hours');

    console.log(hoursSinceInitiated);

    if (hoursSinceInitiated > 48) {
      throwMessage('This request for payment has expired after over 48 hours of inactivity. Please double-check with your referrer or request a new link to pay.');
      return false;
    }

    if (paymentRequestRecord?.error) {
      throwMessage('We were unable to find a record matching that ID. Please double-check with your referrer or request a new link to pay.');
      return false;
    }

    if (paymentRequestRecord.payment_complete) {
      throwMessage('This bill has already been paid, thank you!')
      return false;
    }

    if (paymentRequestRecord.cancelled) {
      throwMessage('This request for payment was cancelled by the business and can no longer be paid. If you believe a bill is due on your behalf, please request another invite from the business.')
      return false;
    }

    setPaymentRequestRecord(paymentRequestRecord);
    const { client_id: business_id } = paymentRequestRecord;

    const business = (await apiRequest({ endpoint: 'v3/publicBusiness', parameters: { business_id } }))?.data?.data;
    console.log(business);
    setBusiness(business);

    if (business?.isUsingRosswarePay === 1) {
      setProcessor('rosswarepay');
    } else {
      setProcessor('tsys');
      throwMessage(null);
    }
  }

  const doFullSteamSetup = useCallback(paymentRequestRecord => {
    // FIRST GRAB THE AUTH KEY
    fetch(`${getRosswarepayEndpointUrl('onlineBillPayAuth')}${ encodeObject({
      unique_payment_key: paymentRequestRecord.unique_id,
    }) }`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('We were unable to authenticate your payment request. Please double-check with your referrer or request a new link to pay.');
        }
        return response.json()
      })
      // THEN SETUP THE FIELDS FOR THE FULLSTEAM PAYMENT
      .then(data => {
        setPaymentAuth(data);
        const setupFullsteamHostedPaymentsParameters = {
          disableDuplicateDetection: false,

          authenticationKey: data?.authKey?.authenticationKey,
          operationType: 'Payment',
          cardEntryContext: 'WebConsumerInitiated',
          formId: 'fullsteam-hosted-form',

          nameOnAccountField: 'fullsteam-hosted-name-on-card-input',
          zipField: 'fullsteam-hosted-zip-input',
          address1Field: 'fullsteam-hosted-address-input',

          // nameOnAccount: payer_name,
          paymentAmount: data?.amountDetails?.total,
          taxAmount: 0,

          customerId: paymentRequestRecord.client_id,
          invoiceNumber: paymentRequestRecord.invoice_number,

          ignoreFormSubmit: false,

          completionCallback: () => {
            const responseJson = JSON.parse(responseTextAreaRef.current.value);
            if (responseJson?.gatewayResponse?.accountDetails) {
              responseJson.gatewayResponse.accountDetails.cardBrand = hostedPaymentsPerformBinCheck(responseJson?.gatewayResponse?.accountDetails?.cardBIN);
            }
            console.log(responseJson);

            completePaymentInOnlinePayments(paymentRequestRecord, responseJson).then(data => {
              setGlobalMessage('Your payment was completed successfully! Thank you for using our service.');
            }).catch(err => {console.log(err);})

          },

          showPleaseWait: () => {
            setProcessingPayment(true);
          },
          hidePleaseWait: () => {
            setProcessingPayment(false);
          },

          validationCallback: () => {
            // maybe show validation error here
            return true;
          },

          hostedPaymentsSimpleError: (err) => {
            setProcessingError(err)
          },

          // validations
          cardNumberValid: () => {
            setPayerCardNumberValid(true);
          },
          cardNumberInvalid: () => {
            setPayerCardNumberValid(false);
          },

          cvvValid: () => {
            setPayerSecurityCodeValid(true);
          },
          cvvInvalid: () => {
            setPayerSecurityCodeValid(false);
          },

          expirationMonthValid: () => {
            setPayerExpirationMonthValid(true);
          },
          expirationMonthInvalid: () => {
            setPayerExpirationMonthValid(false);
          },

          expirationYearValid: () => {
            setPayerExpirationYearValid(true);
          },
          expirationYearInvalid: () => {
            setPayerExpirationYearValid(false``);
          },
        }
        window.setupFullsteamHostedPayments(setupFullsteamHostedPaymentsParameters);
        waitForCardElements().then(() => {
          throwMessage(null); // we do this just to set globalLoading to false
        });
      }).catch(err => {
        throwMessage(err.message);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (processor === 'rosswarepay') {
      if (paymentRequestRecord && fullsteamPayScriptLoaded) {
        doFullSteamSetup(paymentRequestRecord);
      }
    }
  }, [fullsteamPayScriptLoaded, paymentRequestRecord, doFullSteamSetup, processor])

  function completePaymentInOnlinePayments(paymentRequestRecord, fullsteamResponse) {
    return new Promise((resolve, reject) => {
      fetch(`${getRosswarepayEndpointUrl('completeBillPay')}${ encodeObject({
        unique_id: paymentRequestRecord.unique_id,
        business_id: paymentRequestRecord.client_id,
        fullsteam_response: JSON.stringify(fullsteamResponse)
      }) }`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        }).catch(err => {
          throwMessage(err.message);
        }
      );
    })
  }

  function onRosswarePayInputChange (name, value) {
    switch (name) {
      case 'name_on_card':
        setPayerNameValid(validateNonFullsteamField('fullname', value));
        break;
      case 'zip_code':
        setPayerZipValid(validateNonFullsteamField('zip', value));
        break;
      case 'address':
        setPayerAddressValid(validateNonFullsteamField('address', value));
        break;
      default:
        break;
    }
  }

  const isTsysValid = useCallback(() => {
    return validateTsysPaymentForm(tsysInputs) && termsAgreed;
  }, [tsysInputs, termsAgreed]);

  const onTsysInputChange = useCallback((event) => {
    const storedInputs = tsysInputs;
    if (event) {
      storedInputs[event.target.name] = event.target.value;
    }
    setTsysInputs(storedInputs);
    setTsysValid(isTsysValid());
  }, [tsysInputs, isTsysValid]);

  async function chargeTsysPayment () {
    setProcessingPayment(true);
    const { id: payment_id } = paymentRequestRecord;
    const {
      credit_card_number,
      credit_card_security_code,
      credit_card_expiration,
      customer_payer_name,
      customer_zip_code,
    } = tsysInputs;
    const chargePaymentResponse = (await apiRequest({
      endpoint: 'v3/transactOnlinePayment', parameters: {
        payment_id,
        credit_card_number,
        security_code: credit_card_security_code,
        expiration_date: credit_card_expiration,
        payer_name: customer_payer_name,
        payer_zip_code: customer_zip_code,
      }
    }))?.data;
    if (chargePaymentResponse.status === 200 && chargePaymentResponse.message === 'Success') {
      setGlobalMessage('Your payment was completed successfully! Thank you for using our service.');
    } else {
      setProcessingPayment(false);
      setProcessingError(chargePaymentResponse.message);
    }
  }

  const showPaymentForms = () => {
    return _.every([processingPayment], (val) => {return (val === false || val === null)});
  }

  useEffect(() => {
    onTsysInputChange();
  }, [termsAgreed, onTsysInputChange])

  // renderers

  function renderRosswarePayForm () {
    function renderAmounts () {
      if (paymentAuth?.rosswarePayConfiguration?.enable_platform_fee) {
        return (
          <div>
            <h3 style={{ marginBottom: 0 }}>{currency(paymentAuth?.amountDetails?.subtotal).format()}</h3>
            + {currency(paymentAuth?.amountDetails?.platform_fee).format()} {paymentAuth?.rosswarePayConfiguration?.platform_fee_label}<br />
            <b>Amount due: {currency(paymentAuth?.amountDetails?.total).format()}</b>
          </div>
        );
      } else {
        return (
          <div>
            <b>Amount due: </b>
            <h3 style={{ marginBottom: 0 }}>{currency(paymentAuth?.amountDetails?.total).format()}</h3>
          </div>
        );
      }
    }
    return (
      <Fragment>
        <div className="m-4 mb-3 grid-2-col">
          { renderAmounts() }

          <div className="text-right">
            <div className="text-black-50">Requested: {dateToISO(paymentRequestRecord?.when_requested)}</div>
            <div className="text-black-50">{paymentRequestRecord?.description || 'For services/materials provided'}</div>
          </div>
        </div>
        <form id="fullsteam-hosted-form" className="fullSteampayForm" onSubmit={(event) => {
          event.preventDefault();
        }}>

        <div className="px-4 py-3">
          <div>
            <ValidityLabel valid={payerNameValid}>Name on card</ValidityLabel>
            <input name="name_on_card" className='styled-input' id="fullsteam-hosted-name-on-card-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="John N Doe"/>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
              <div>
                <ValidityLabel valid={payerAddressValid}>Street #</ValidityLabel>
                <input name="address" className='styled-input' id="fullsteam-hosted-address-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="123"/>
              </div>
              <div>
                <ValidityLabel valid={payerZipValid}>Zip/postal code</ValidityLabel>
                <input name="zip_code" className='styled-input' id="fullsteam-hosted-zip-input" onChange={ (e) => onRosswarePayInputChange(e.target.name, e.target.value) } placeholder="98584"/>
              </div>
            </div>

            <ValidityLabel valid={payerCardNumberValid}>Card number</ValidityLabel>
            <div className='styled-input' id="fullsteam-hosted-card-number-div"></div>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
              <div>
                <ValidityLabel valid={payerExpirationMonthValid}>Expiration month</ValidityLabel>
                <div className='styled-input' id="fullsteam-hosted-expiration-month-div"></div>
              </div>
              <div>
                <ValidityLabel valid={payerExpirationYearValid}>Expiration year</ValidityLabel>
                <div className='styled-input' id="fullsteam-hosted-expiration-year-div"></div>
              </div>
            </div>

            <ValidityLabel valid={payerSecurityCodeValid}>Security code</ValidityLabel>
            <div className='styled-input' id="fullsteam-hosted-cvv-div"></div>

            <input type="hidden" id="Amount" name="Amount"></input>
            <input type="hidden" id="InvoiceId" name="InvoiceId"></input>
            <input type="hidden" id="TransactionId" name="TransactionId"></input>
            <textarea readOnly hidden ref={responseTextAreaRef} id="HostedPaymentsResponse" name="HostedPaymentsResponse" style={{ fontSize: '8pt', height: '300px', width: '100%' }}></textarea>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <Form.Check checked={termsAgreed} type="checkbox" onChange={() => setTermsAgreed(!termsAgreed)} />
              <span>I agree to <a href="https://rossware.com/terms" target="_blank" rel="noreferrer">terms of use.</a></span>
            </div>
            <Button type="submit" className="btn-primary px-5 py-2" disabled={!_.every(rosswarePayRequiredValids, (val) => val === true)}>Submit</Button>
          </div>
        </div>
        </form>
      </Fragment>
    )
  };

  function renderTSYSForm() {
    return (
      <Fragment>
        <div className="m-4 mb-3 grid-2-col">
          <div>
            <b>Amount due: </b>
            <h3 style={{ marginBottom: 0 }}>{currency(paymentRequestRecord?.amount_to_charge).format()}</h3>
          </div>

          <div className="text-right">
            <div className="text-black-50">Requested: {dateToISO(paymentRequestRecord?.when_requested)}</div>
            <div className="text-black-50">{paymentRequestRecord?.description || 'For services/materials provided'}</div>
          </div>
        </div>

        <div className="px-4 py-3">
          <Form.Group className="mb-3">
            <Form.Label>Card Info</Form.Label>
            <InputGroup className="mb-3">
              <FormControl defaultValue={tsysInputs.customer_first_name} name="customer_payer_name" onChange={(event) => onTsysInputChange(event)} placeholder="Cardholder name" />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl className="last-input-fix" defaultValue={tsysInputs.customer_zip_code} as={InputMask} mask={business.IsCanadian ? 'a9a9a9' : '99999'} name="customer_zip_code" onChange={(event) => onTsysInputChange(event)} placeholder={business.IsCanadian ? "Postal Code" : "Zip Code"} />
            </InputGroup>
            <Form.Control defaultValue={tsysInputs.credit_card_number} as={InputMask} mask={'9999999999999999'} maskChar={ null } name="credit_card_number" onChange={(event) => onTsysInputChange(event)} type="tel" placeholder="Card number" />
          </Form.Group>

          <InputGroup className="mb-4">
            <FormControl defaultValue={tsysInputs.credit_card_security_code} as={InputMask} mask={'9999'} maskChar={ null } name="credit_card_security_code" onChange={(event) => onTsysInputChange(event)} placeholder="Security Code" />
            <FormControl className="last-input-fix" defaultValue={tsysInputs.credit_card_expiration} as={InputMask} mask={'99/99'} name="credit_card_expiration" onChange={(event) => onTsysInputChange(event)} placeholder="Expiration" />
          </InputGroup>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <Form.Check checked={termsAgreed} type="checkbox" onChange={() => setTermsAgreed(!termsAgreed)} />
              <span>I agree to <a href="https://rossware.com/terms" target="_blank" rel="noreferrer">terms of use.</a></span>
            </div>
            <Button onClick={() => chargeTsysPayment()} className="btn-primary px-5 py-2" disabled={!tsysValid}>Submit</Button>
          </div>
        </div>

      </Fragment>
    )
  }

  const renderProcessingError = useCallback(() => {
    if (!processingError || processingPayment) {
      return undefined;
    }
    return (
      <Alert variant='danger' className='m-2'>{processingError}</Alert>
    )
  }, [processingPayment, processingError])

  return (
    <Fragment>
      { globalMessage &&
        <div className="global-message-div">
          <div className='global-message'>
            { globalMessage }
          </div>
        </div>
      }
      { globalLoading &&
        <div className="global-message-div">
          <div className='global-message'>
            Loading, please wait...
          </div>
        </div>
      }


      <div className="vh-100 d-flex justify-content-center align-items-center overflowXNone">
        <Card className="wrapper">
          <div className="border-bottom px-4 py-3 text-center">
            <h5 className="m-0 text-center">{business?.ClientName}</h5>
            <p className='mb-0'>is requesting a payment</p>
          </div>

          <div className={ !showPaymentForms() ? 'd-none' : undefined }>
            {processor === 'rosswarepay' && renderRosswarePayForm()}
            {processor === 'tsys' && renderTSYSForm()}
          </div>

          {processingPayment &&
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner className='m-4' animation="border" role="status" variant="primary">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          }

          {renderProcessingError()}

          <div className="legal-links">
            <a href="https://rossware.com/contact">Contact us</a>
            <a href="https://rossware.com/privacy-policy">Privacy policy</a>
          </div>

          <div className="px-4 py-2 rounded-bottom d-flex justify-content-between align-items-center bg-light">
            <img src={SSL} width="171" alt="SSL Secured" className="d-none d-md-inline" />
            <img src={Methods} width="200px" alt="Payment methods" />
          </div>
        </Card>
      </div>
    </Fragment>
  )

}
