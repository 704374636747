import _ from 'lodash'

export function validateText(value, requiredLength = 1) {
  if (value && (value.length >= requiredLength) && (value !== 'default') && (value !== '')) { return true }
  return false;
}

export function validateEmail(value) {
  if (!!!value) { return false }
  if (value.match(/^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/)) { return true }
  return false;
}

export function validateDate(value) {
  if (value === null) { return false } else { return true }
}

export function validateTime(value) {
  if (value.length > 0) { return true } else { return false }
}

export function validateAddress(value) {
  if (value.length > 0) { return true } else { return false }
}

export function validateFourDigitExpirationDate(value) {
  if (!value) { return false };
  const parts = value.split('/');
  if (_.every(parts, part => {
    return part.length === 2 && part.match(/^[0-9]{2}$/);
  })) {
    if (parts[0] < 13) {
      return true;
    }
  }
  return false;
}

export function validateZip(value) {

  if (!value) { return false }
  if (value.match(/^\d{5}$/) || value.match(/^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/)) {
    return true;
  } else {
    return false;
  }

}

export function validatePhone(value) {
  var strippedPhone = value.replace(/\D/g,'');
  if(strippedPhone.length===10||strippedPhone.length===11){
      return true;
  }else{
      return false;
  }
}

export function validateTsysPaymentForm(inputs) {
  if (!!!validateText(inputs.customer_payer_name)) { return false }
  if (!!!validateZip(inputs.customer_zip_code)) { return false }
  if (!!!validateFourDigitExpirationDate(inputs.credit_card_expiration)) { return false }
  if (!!!validateText(inputs.credit_card_number, 15)) { return false }
  if (!!!validateText(inputs.credit_card_security_code, 3)) { return false }
  return true;
}

export function validateNonFullsteamField(type, value) {
  switch (type) {
    case 'zip':
      if (value.match(/^\d{5}$/) || value.match(/^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/)) {
        return true;
      } else {
        return false;
      }
    case 'fullname':
      return !!(value.match(/^[a-z ,.'-]+$/i));
    case 'address':
      return value.length > 0;
    default: return false;
  }
}